<template lang="pug">
  .senders.view
    section.compact.senders-title
      .h1 Абоненты
    section.compact.senders-list(v-if="!subscriber.deny_all_subscriptions")
      .section-content-wrapper
        template(v-if="subscriptions.total")
          .table
            .th
              .td Телефон
              .td Отправитель
              .td Канал
              .td Статус
            .tr.striped(v-for="subscription in subscriptions.data" :key="subscription.id")
              .td
                b {{ subscription.phone }}
              .td.name.tablets--inline
                b {{ subscription.sender_name }}

              .td.channel.tablets--inline {{ subscription.channel_alias }}
              .td
                el-switch(v-model="subscription.unsubscribed" :active-text="subscription.unsubscribed === false ? 'Подписан' : subscription.unsubscribed === true ? 'Отписан' : 'Отпишитесь'" :class="subscription.unsubscribed ? 'active' : subscription.unsubscribed === false ? 'inactive' : ''")
          .pagination.senders-list-pagination(v-if="subscriptions.to < subscriptions.total")
            el-button(@click="loadMoreSenders" type="primary") Показать ещё
</template>

<script>
import {mapActions, mapGetters, mapMutations} from 'vuex'
import {
  RESET_STORE,
  GET_SENDERS_SUGGESTIONS,
  GET_SUBSCRIPTIONS,
  SAVE_SUBSCRIBER,
  SAVE_SUBSCRIBER_SUBSCRIPTION,
} from '@/store/types'

import SubscriptionEditForm from "@/components/Subscription/SubscriptionEditForm"
import UnsubscribeFromAll from "@/components/Subscription/UnsubscribeFromAll"
import SendersFilterForm from "@/components/Senders/SendersFilterForm"

const senders_default_filter_form = () => {
  return {
    sorting: {
      property: 'frequency',
      direction: 'desc',
    },
    channel: {alias: 'sms'},
    tags: [],
    name: '',
  }
}

const default_subscriptions = () => {
  return {
    empty_message: 'Не найдено ни одного абонента',
    current_page: 1,
    total: 0,
  }
}

export default {
  metaInfo() {
    return {
      title: this.title
    }
  },
  components: {
    SubscriptionEditForm,
    UnsubscribeFromAll,
    SendersFilterForm,
  },
  data() {
    return {
      subscription_form_visible: false,
      senders_filter_visible: true,
      terms_dialog_visible: false,
      senders_filter_form: senders_default_filter_form(),
      channels: [{
        alias: 'sms',
        name: 'SMS',
      }],
      sorting_directions: [
        'desc',
        'asc',
      ],
      subscriptions: [],
      senders: default_subscriptions(),
    }
  },
  computed: {
    ...mapGetters([
      'auth_user',
      'subscriber',
    ]),
    title() {
      return 'Популярные отправители'
    },
  },
  created() {
    this.getSubscriptions()

    if (!this.subscriber.terms_accepted_at) this.terms_dialog_visible = true
  },
  methods: {
    ...mapActions([
      GET_SENDERS_SUGGESTIONS,
      GET_SUBSCRIPTIONS,
      SAVE_SUBSCRIBER,
      SAVE_SUBSCRIBER_SUBSCRIPTION,
    ]),
    ...mapMutations([
      RESET_STORE,
    ]),
    logout() {
      this.RESET_STORE()

      this.$router.push({name: 'index'})
    },
    acceptTerms() {
      this.subscriber.terms_accepted_at = this.$moment()

      return this.SAVE_SUBSCRIBER(this.subscriber).then(() => {
        this.terms_dialog_visible = false
      }).catch((error) => {
        this.$root.pushAppMessages(error.response.data)

        return Promise.reject(error)
      })
    },
    onSubscriptionSaved() {
      this.toggleSubscriptionForm()
      this.$router.push({name: 'subscriptions'})
    },
    toggleSubscriptionForm() {
      this.subscription_form_visible = !this.subscription_form_visible
    },
    getSubscriberSubscriptions() {
      return this.GET_SUBSCRIBER_SUBSCRIPTIONS({subscriber: this.subscriber}).then((subscriptions) => {
        Object.assign(this.subscriptions, subscriptions)
      }).catch((error) => {
        if(error.response.data.errors){
          this.$root.pushAppMessages(error.response.data.errors.map((message)=>{
            return this.$t(message)
          }))
        }

        return Promise.reject(error)
      })
    },
    resetSenders() {
      this.senders = default_subscriptions()
    },
    filterSenders() {
      this.getSenders(true)
    },
    sortSenders(property) {
      this.senders_filter_form.sorting.property = property
      this.sorting_directions.reverse()
      this.senders_filter_form.sorting.direction = this.sorting_directions[0]
    },
    loadMoreSenders() {
      this.senders.current_page++

      return this.getSenders()
    },
    getSubscriptions(with_reset) {
      return this.GET_SUBSCRIPTIONS({...{page: this.senders.current_page}}).then((subscriptions) => {

        let list = (this.subscriptions.data) ? this.subscriptions.data : []

        this.subscriptions = subscriptions

        console.log(this.subscriptions)

        Object.assign(this.subscriptions.data, list.concat(this.subscriptions.data))
      }).catch((error) => {
        if(error.response.data.errors){
          this.$root.pushAppMessages(error.response.data.errors.map((message)=>{
            return this.$t(message)
          }))
        }

        return Promise.reject(error)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.senders {
  .deny-all {
    background: $color--secondary;
    color: $color--black;
  }

  &-title {
    background: $color--background;
    color: $color--black;
  }

  &-filter {
    width: 50%;

    @media (max-width: $max-width--tablets) {
      width: 100%;
    }
  }

  &-list {
    background: $color--background;
    color: $color--black;

    @media (max-width: $max-width--tablets) {
      .tr {
        @include border($position: bottom);

        padding: $padding18 0;

        .td {
          padding-top: 0;

          &.tablets--inline {
            display: inline-block;
          }

          &.channel {
            float: right;

          }
        }
      }
    }
  }

  .subscription-edit {
    &-form {
      width: 50%;
    }

    @media (max-width: $max-width--tablets) {
      &-form {
        width: 100%;
      }
    }
  }
}
</style>
